<template>
  <a-spin :spinning="spinningLoadingPage">
    <div>
      <b-card>
        <b-row>
          <!-- ==start-content-left== -->
          <b-col sm="4">
            <b-card no-body class="border mt-1">
              <b-card-header class="p-1 bg-light-primary">
                <b-card-title class="font-medium-1">
                  <feather-icon icon="FileIcon" size="16" />
                  <span class="align-middle ml-50">Menu Integrasi</span>
                </b-card-title>
              </b-card-header>
              <b-row class="p-1">
                <v-treeview v-model="treeData" :treeTypes="treeTypes" @selected="onSelectedMenu" :openAll="openAll" :contextItems="contextItems" @contextSelected="contextSelectedTree"> </v-treeview>
              </b-row>
            </b-card>
          </b-col>
          <!-- ==end-content-left== -->

          <!-- ==start-content-right== -->
          <b-col sm="8">
            <b-card no-body class="border mt-1">
              <b-card-header class="p-1 bg-light-primary">
                <b-card-title class="font-medium-1">
                  <feather-icon icon="FileIcon" size="16" />
                  <span class="align-middle ml-50">Page Integrasi Data {{ toTitleCase(REPORT_SELECTED) }}</span>
                </b-card-title>
              </b-card-header>
              <a-spin :spinning="spinningLoading">
                <div style="padding: 20px">
                  <div v-if="ALLOW_WMO_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="STASIUN">
                      <a-select show-search :value="WMO_SELECTED" style="width: 100%" :filter-option="false"
                        :not-found-content="null" @search="fetchWmo" @change="onChangeWMO">
                        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="(wmo, indexWmo) in STORE_WMO_SELECT" :key="indexWmo"
                          :value="wmo.station_wmo_id"> {{ wmo.station_wmo_id }} - {{ wmo.station_name }}
                        </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                 <div v-if="ALLOW_FILE_OPTION.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="FILE">
                      <a-select :v-model="FILE_SELECTED" style="width: 100%"  @change="onChangeFile" >
                        <a-select-option v-for="(file, indexFile) in FILE_LIST" :key="indexFile" :value="file.value" > {{ file.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <div v-if="ALLOW_FILE_RASON_OPTION.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PRODUK">
                      <a-select :v-model="FILE_RASON_SELECTED" style="width: 100%"  @change="onChangeFileRason" >
                        <a-select-option v-for="(file, indexFile) in FILE_RASON_LIST" :key="indexFile" :value="file.value" > {{ file.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>

                   <div v-if="ALLOW_RAW_DATA_PETIR_OPTION.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PRODUK">
                      <a-select :v-model="RAW_DATA_PETIR_SELECTED" style="width: 100%"  @change="onChangeFileRawDataPetir" >
                        <a-select-option v-for="(file, indexFile) in RAW_DATAP_ETIR_LIST" :key="indexFile" :value="file.value" > {{ file.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>

                  <div v-if="ALLOW_SUMBER_PETIR.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="SUMBER">
                      <a-select :v-model="SUMBER_SELECTED" style="width: 100%"  @change="onChangeSUMBER" >
                        <a-select-option v-for="(sumber, indexSumber) in SUMBER_LIST" :key="indexSumber" :value="sumber.value" > {{ sumber.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                 
                 

                   <!-- list produk -->
                  <div v-if="ALLOW_PRODUK_LIST_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PRODUK">
                      <a-select :v-model="PRODUK_SELECTED" style="width: 100%"  @change="onChangeProdukList">
                        <a-select-option v-for="(produk, indexProduk) in PRODUK_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list produk -->
                  <div v-if="ALLOW_TIPE_DATA_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="TIPE DATA">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="TIPE_DATA_SELECTED" style="width: 100%"  @change="onChangeTipeData">
                        <a-select-option v-for="(periode, indexPeriode) in TIPE_DATA_LIST" :key="indexPeriode" :value="periode.value"> {{ periode.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                       <!-- list produk -->
                  <div v-if="ALLOW_LOKASI_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="LOKASI/SITE">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="LOKASI_SELECTED" style="width: 100%"  @change="onChangeLokasi">
                        <a-select-option v-for="(produk, indexProduk) in LOKASI_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list produk -->
                     <!-- list produk -->
                  <div v-if="ALLOW_PRODUK_SATELIT_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PRODUK">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="PRODUK_SELECTED_SATELIT" style="width: 100%"  @change="onChangeProdukSatelit">
                        <a-select-option v-for="(produk, indexProduk) in PRODUK_SATELIT_LIST[TIPE_DATA_SELECTED]" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                   <div v-if="ALLOW_PRODUK_RADAR_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PRODUK">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="PRODUK_SELECTED_RADAR" style="width: 100%"  @change="onChangeProdukRadar">
                        <a-select-option v-for="(produk, indexProduk) in PRODUK_RADAR_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list produk -->
                  
                
                   <!-- list periode harian atau Pekanan -->
                  <div v-if="ALLOW_PARAMETER.indexOf(REPORT_SELECTED) != -1 && PRODUK_SELECTED_RADAR!='vol'">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PARAMETER">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select v-model="PARAMETER_SELECTED"  style="width: 100%"  @change="onChangeParameterList">
                        <a-select-option v-for="(parameter, indexParameter) in PARAMETER_LIST" :key="indexParameter" :value="parameter.value"> {{ parameter.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list periode harian atau pekanan -->
                  
                      <!-- list channel -->
                  <div v-if="ALLOW_CHANNEL_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="CHANNEL">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="CHANNEL_SELECTED" style="width: 100%"  @change="onChangeChannel">
                        <a-select-option v-for="(produk, indexProduk) in CHANNEL_LIST[PRODUK_SELECTED_SATELIT]" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list channel -->
                      <!-- list channel -->
                  <div v-if="ALLOW_FORMAT_DATA_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="FORMAT DATA">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="FORMAT_DATA_SELECTED" style="width: 100%"  @change="onChangeFormatData">
                        <a-select-option v-for="(produk, indexProduk) in FORMAT_DATA_LIST[CHANNEL_SELECTED]" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list channel -->
                   <!-- ==start-range-picker== -->
                   <div v-if="ALLOW_DATE_RANGE_PICKER.indexOf(REPORT_SELECTED) != -1  && PERIODE_SELECTED !='Pekanan' && PRODUK_SELECTED_RADAR != 'vol'">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="DATE RANGE">
                      <a-range-picker
                        @change="onChangeRangeDate"
                        :value="DATE_RANGE_SELECTED != null && DATE_RANGE_SELECTED != '' ? [moment(DATE_RANGE_SELECTED[0], 'YYYY-MM-DD'), moment(DATE_RANGE_SELECTED[1], 'YYYY-MM-DD')] : DATE_RANGE_SELECTED"
                      />
                    </b-form-group>
                  </div>
                   <!-- leaflet maps -->
                  <div v-if="ALLOW_MAPS_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="MAPS">
                      <div id="map" style="width: 100%; height: 400px;">
                       <l-map :zoom="zoom" :center="[LATITUDE_SELECTED,LONGITUDE_SELECTED]" @click="updateCoordinates" style="z-index:1;height:100%">
                        <l-marker draggable="true" :icon="icon" :lat-lng="[LATITUDE_SELECTED,LONGITUDE_SELECTED]" @drag="updateCoordinates">
                        </l-marker>
                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                        <l-circle ref="circle" :lat-lng="[LATITUDE_SELECTED, LONGITUDE_SELECTED]" :radius="RADIUS_SELECTED*1000">
                        </l-circle>
                      </l-map>
                      </div>
                    </b-form-group>
                  </div>
                  <!-- latitude -->
                  <div v-if="ALLOW_LATITUDE_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="LATITUDE">
                      <a-input  v-model="LATITUDE_SELECTED" />
                    </b-form-group>
                  </div>
                  <!-- longitude -->
                  <div v-if="ALLOW_LONGITUDE_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="LONGITUDE">
                      <a-input v-model="LONGITUDE_SELECTED" />
                    </b-form-group>
                  </div>
                  <!-- radius -->
                  <div v-if="ALLOW_RADIUS_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="RADIUS (KM)">
                      <a-input  v-model="RADIUS_SELECTED" />
                    </b-form-group>
                  </div>
                  <!-- buat checkbox untuk print_pdf -->
                  <div v-if="ALLOW_RADIUS_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="">
                       <b-form-checkbox v-model="IS_PRINT_TABLE_PETIR">CETAK TABLE</b-form-checkbox>
                       <b-form-checkbox v-model="IS_PRINT_MAPS_PETIR">CETAK MAPS</b-form-checkbox>
                    </b-form-group>
                  </div>
                  <!-- end buat checkbox untuk print_pdf -->
                  
                  
                  <!-- list periode harian atau pekanan -->
                  <div v-if="ALLOW_PERIODE_LIST_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="PERIODE">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select :v-model="PERIODE_SELECTED" style="width: 100%"  @change="onChangePeriodeList">
                        <a-select-option v-for="(periode, indexPeriode) in PERIODE_LIST" :key="indexPeriode" :value="periode.value"> {{ periode.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                   <!-- end list periode harian atau pekanan -->
                   <!-- list periode harian atau Pekanan -->
                  <div v-if="ALLOW_NAMA_FILE_LIST_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="NAMA FILE">
                     <!-- a select using static option pekanan dan harian -->
                      <a-select v-model="NAMA_FILE_SELECTED"  style="width: 100%"  @change="onChangeNamaFileList">
                        <a-select-option v-for="(nama_file, indexNamaFile) in NAMA_FILE_LIST" :key="indexNamaFile" :value="nama_file.value"> {{ nama_file.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <!-- end list periode harian atau pekanan -->
                  <div v-if="ALLOW_DATE_PICKER.indexOf(REPORT_SELECTED) != -1 && PERIODE_SELECTED != 'Pekanan'">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="DATE">
                      <a-date-picker placeholder="Select Date" @change="onChangeDate"   
                       :value="DATE_SELECTED != null && DATE_SELECTED != '' ? moment(DATE_SELECTED, 'YYYY-MM-DD') : DATE_SELECTED" />
                    </b-form-group>
                  </div>
                  <div v-if="ALLOW_DATE_TIME_START_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="START">
                      <a-date-picker placeholder="Select Date" @change="onChangeDateStart"   
                       :value="DATE_START_SELECTED != null && DATE_START_SELECTED != '' ? moment(DATE_START_SELECTED, 'YYYY-MM-DD') : DATE_START_SELECTED" />
                      <a-select v-if="PRODUK_SELECTED_RADAR!='vol'" :v-model="HOUR_START_SELECTED" style="width: 30%;padding-left:10px"  @change="onChangeHourStart">
                        <a-select-option v-for="(produk, indexProduk) in HOUR_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                      <a-select v-if="PRODUK_SELECTED_RADAR!='vol'" :v-model="MINUTE_START_SELECTED" style="width: 30%;padding-left:10px"  @change="onChangeMinuteStart">
                        <a-select-option v-for="(produk, indexProduk) in MINUTE_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                  <div v-if="ALLOW_DATE_TIME_END_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="END">
                      <a-date-picker placeholder="Select Date" @change="onChangeDateEnd"   
                       :value="DATE_END_SELECTED != null && DATE_END_SELECTED != '' ? moment(DATE_END_SELECTED, 'YYYY-MM-DD') : DATE_END_SELECTED" />
                    <a-select v-if="PRODUK_SELECTED_RADAR!='vol'" :v-model="HOUR_END_SELECTED" style="width: 30%;padding-left:10px"  @change="onChangeHourEnd">
                        <a-select-option v-for="(produk, indexProduk) in HOUR_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                      <a-select v-if="PRODUK_SELECTED_RADAR!='vol'" :v-model="MINUTE_END_SELECTED" style="width: 30%;padding-left:10px"  @change="onChangeMinuteEnd">
                        <a-select-option v-for="(produk, indexProduk) in MINUTE_LIST" :key="indexProduk" :value="produk.value"> {{ produk.label }} </a-select-option>
                      </a-select>
                      </b-form-group>
                  </div>
                    <div v-if="ALLOW_DATE_START_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="START">
                      <a-date-picker placeholder="Select Date" @change="onChangeDateStart"   
                       :value="DATE_START_SELECTED != null && DATE_START_SELECTED != '' ? moment(DATE_START_SELECTED, 'YYYY-MM-DD') : DATE_START_SELECTED" />
                    </b-form-group>
                  </div>
                  <div v-if="ALLOW_DATE_END_PICKER.indexOf(REPORT_SELECTED) != -1">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="END">
                      <a-date-picker placeholder="Select Date" @change="onChangeDateEnd"   
                       :value="DATE_END_SELECTED != null && DATE_END_SELECTED != '' ? moment(DATE_END_SELECTED, 'YYYY-MM-DD') : DATE_END_SELECTED" />
                      </b-form-group>
                  </div>
                  <!-- make datepicker -->
                  <div v-if="ALLOW_DATE_PICKER.indexOf(REPORT_SELECTED) != -1 && PERIODE_SELECTED == 'Pekanan'">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="DATE">
                      <a-date-picker placeholder="Select Date" @change="onChangeDate"   
                      :disabled-date="(date) => date.day() != 1 && date.day() != 4"
                       :value="DATE_SELECTED != null && DATE_SELECTED != '' ? moment(DATE_SELECTED, 'YYYY-MM-DD') : DATE_SELECTED" />
                    </b-form-group>
                  </div>
                  
                  <div v-if="ALLOW_TIME.indexOf(REPORT_SELECTED) != -1 && PRODUK_SELECTED != 'Analisis'">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="INITIAL FORECAST">
                      <a-select show-search :value="TIME_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangeTIME">
                        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="(timeVal, indexTime) in STORE_TIME" :key="indexTime" :value="timeVal">
                          {{ timeVal }}
                        </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>

                  <div v-if="ALLOW_TIME.indexOf(REPORT_SELECTED) != -1 && PRODUK_SELECTED == 'Forecast'">
                   <!-- buat input text inline range start dan range end-->
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="RANGE FORECAST">
                      <a-input-group compact>
                        <a-input  style="width:20%" v-model="RANGE_START"/>
                        <a-input  style="width:20%" v-model="RANGE_END" />
                      </a-input-group>
                    </b-form-group>
                   
                  </div>

                   <div v-if="ALLOW_TIME_00.indexOf(REPORT_SELECTED) != -1 && PRODUK_SELECTED == 'Analisis'">
                    <b-form-group label-cols="4" label-cols-lg="4" label-size="sm" label="INITIAL FORECAST">
                      <a-select show-search :value="TIME_SELECTED" style="width: 100%" :filter-option="false" :not-found-content="null" @search="fetchWmo" @change="onChangeTIME">
                        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                        <a-select-option v-for="(timeVal, indexTime) in STORE_TIME" :key="indexTime" :value="timeVal">
                          {{ timeVal }}
                        </a-select-option>
                      </a-select>
                    </b-form-group>
                  </div>
                 

                  <div style="text-align: right; padding: 10px">
                    <a-button type="primary"  @click="ok"> Process </a-button>
                  </div>
                </div>
              </a-spin>

            </b-card>
            <div>
              <b-card no-body class="border mt-1">
                <b-card-header class="p-1 bg-light-primary">
                  <b-card-title class="font-medium-1">
                    <feather-icon icon="FileIcon" size="16" />
                    <span class="align-middle ml-50">List Data {{ toTitleCase(REPORT_SELECTED) }}</span>
                  </b-card-title>
                </b-card-header>
                <div style="text-align: right; padding: 10px">
                    <download-excel v-if="downloadPetir" class="btn btn-sm btn-success ml-1" :data="table_items" type="csv" :name="csvfilename"
                      :escapeCsv="escapeCsv" > Download CSV
                    </download-excel>
                    
               <a-button v-if="downloadPetir"  style="margin:10px" type="warning" @click="generateReport()"> Download PDF </a-button>
                <span v-if="downloadPetir">
                  <br>
                  keterangan type
                        
                        <!-- 1 = warna hijau -->
                        <!-- 0 ambil dari local path -->
                        0 = <img :src="require('@/assets/images/marker/green-maps-icon.png')" width="10px" height="10px"/>
                        1 = <img :src="require('@/assets/images/marker/yellow-maps-icon.png')" width="10px" height="10px"/> 
                        2 = <img :src="require('@/assets/images/marker/red-maps-icon.png')" width="10px" height="10px"/> 
                </span>      
                </div>
                <div id="app"  style="min-height: 200px; max-height: 525px; overflow: auto">
                  <div v-if="IS_PRINT_MAPS_PETIR && downloadPetir" ref="div_map" id="info-map" style="width: 100%; height: 400px;">
                     
                       <l-map style="z-index:1;height:400px;width:100%" ref="mymap" :zoom="7" :center="[LATITUDE_SELECTED,LONGITUDE_SELECTED]">
                        <l-marker v-for="(location, index) in listMarker"  :lat-lng="[location.latitude,location.longitude]" v-bind:key="index">
                          <l-icon :icon-size="[2, 5]">
                            <img v-if="location.type == 0" width="15px" :src="require('@/assets/images/marker/green-maps-icon.png')" />
                            <img v-else-if="location.type == 1" width="15px" :src="require('@/assets/images/marker/yellow-maps-icon.png')" />
                            <img v-else width="15px" :src="require('@/assets/images/marker/red-maps-icon.png')" />
                          </l-icon>
			                  </l-marker>
                        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                      </l-map>
                  </div>
                  <div  v-if="IS_PRINT_MAPS_PETIR && downloadPetir" class="row">
                    <br/><br/>
                  </div>
                  <vue-good-table
                    class="monitor"
                    v-show="IS_PRINT_TABLE_PETIR"
                    :columns="columns"
                    :rows="table_items"
                    :pagination-options="{
                      enabled: true,
                      perPage: pageLength,
                      setCurrentPage: 1,
                      mode: 'pages',
                    }"
                    :isLoading="isLoading"
                    ref="goodTable"
                  >
                    <template slot="table-row" slot-scope="props">
                      <span v-if="props.column.field === 'wmo'">
                        {{ wmo }}
                      </span>
                      <span v-if="props.column.field === 'download'">
                        <a-button class="button-download" @click="getReport(props.row)" v-if="props.row[props.column.field] == true"> Download </a-button>
                      </span>
                      <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                      </span>
                    </template>
                  </vue-good-table>                  
                </div>
               
              </b-card>
            </div>
        
                    <b-modal 
                    @shown="modalShown"
                    @hidden="$emit('clear')"
                    id="modal-1" size="xl" title="Hasil Peta">
                       
                    </b-modal>  
                    <vue-html2pdf
                      :pdf-styles="pdfStyles"
                      :show-layout="false"
                      :float-layout="true"
                      :enable-download="true"
                      :preview-modal="false"
                      :paginate-elements-by-height="1400"
                      filename="Data PDF"
                      :pdf-quality="2"
                      :manual-pagination="false"
                      pdf-format="a4"
                      pdf-orientation="landscape"
                      pdf-content-width="100%"
                      @progress="onProgress($event)"
                      @hasStartedGeneration="hasStartedGeneration()"
                      @hasGenerated="hasGenerated($event)"
                      ref="html2Pdf"
                  >
                  <section slot="pdf-content" ref="pdfContent">
                  
                  
                      <!-- PDF Content Here -->
                  </section>
              </vue-html2pdf>
          </b-col>
          <!-- ==end-content-right== -->
        </b-row>
      </b-card>
      <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
  </a-spin>
</template>
<script>
import Vue from "vue";
import VTreeview from "v-treeview";
import VueHtml2pdf from "vue-html2pdf";
import DataWMO from "@/api/dummy/store/DataWMO";
import DataStation from "@/api/dummy/store/DataStation";

import DataHelper from "@/api/dummy/store/DataHelper";
import moment from "moment";
import outputintegrasi from "@/api/outputintegrasi";


import { VueGoodTable } from "vue-good-table";
import jwt from "@/auth/jwt/useJwt";
import { BOverlay,BModal, BRow, BCol, BCard, BCardHeader, BCardTitle, BFormGroup,BFormCheckbox, BFormInput, BInputGroup, BFormSelect, BButton, BButtonGroup, BPagination, BAvatar, BBadge, BFormDatepicker } from "bootstrap-vue";

import helper from "@/api/helper";
import axios from "axios";

import { saveAs } from "file-saver";
import metadata from "@/api/metadata";
import integrasi from "@/api/integrasiservice";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ExcelJS from "exceljs";
import flatPickr from "vue-flatpickr-component";
import { LMap, LTileLayer, LMarker,LIcon, LCircle } from 'vue2-leaflet';
import {icon,marker} from 'leaflet';
import 'leaflet/dist/leaflet.css';
export default {
  components: {
    BOverlay,
    BModal,
    BFormCheckbox,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    VTreeview,
    VueHtml2pdf,
    BFormGroup,
    flatPickr,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
    VueGoodTable,
    ExcelJS,
    saveAs,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LCircle,
  },
  computed: {

    
  
  },
  mounted() {
  
  },
  created() {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    this.fetchData(userId) // Fetch data on component creation
    setInterval(() => {
      this.fetchData(userId) // Fetch data every 10 seconds
    }, 60000)
  },
  methods: {
    async fetchData(user_id) {
      
      let r_url = '';
      user_id = 1;
      try {
        //switch case
        if (this.REPORT_SELECTED == 'GCAM'
          || this.REPORT_SELECTED == 'ECMWF'
          || this.REPORT_SELECTED == 'SATELIT'
          || this.REPORT_SELECTED == 'RADAR'
          || this.REPORT_SELECTED == 'RASON'
          || this.REPORT_SELECTED == 'FILE_RASON'
          || this.REPORT_SELECTED == 'RAW_DATA_PETIR'
        ){
          this.isLoading = true;
          this.showLoading = true;
          let report_parameter = this.REPORT_SELECTED.toLowerCase();
          if (this.REPORT_SELECTED == 'RASON'){
            report_parameter = 'bufr';
          }
          if (this.REPORT_SELECTED == 'FILE_RASON'){
            report_parameter = 'rason';
          }
            r_url = helper.getBaseUrl() + '@check_integration?report='+report_parameter+'&user_id='+user_id;

            //axios
            axios.get(r_url,helper.getConfig())
            .then(response => {
              console.log(response.data);
              this.table_items = response.data.result;
              //modify field 
              this.table_items.forEach(function (value, key) {
                value.at_create = moment(value.at_create).format('DD-MM-YYYY HH:mm:ss');
                value.download = 0;
                switch(value.at_flag){
                  case 0:
                    value.status = 'Baru Diregister';
                    break;
                  case 1:
                    value.status = 'Sedang Diproses';
                    break;
                  case 2:
                    value.download = 1;
                    value.status = 'Selesai';
                    break;
                  case 3:
                    value.status = 'File Tidak Ditemukan';
                    break;  
                  default:
                    value.status = 'Error Lainnya';
                    break;
                }
              });
              //sorting by id desc
              this.table_items.sort(function(a, b) {
                return b.id - a.id;
              });
            })
            this.isLoading = false;
            this.showLoading = false;
          }
      }
       catch (error) {
        console.error(error)
      }
    }, 
    generateReport() {
      this.showLoading = true;
      this.isLoading = true;
      const pdfContent = this.$refs.pdfContent;
      let tableCopy = null;
      if (this.IS_PRINT_TABLE_PETIR) {
        tableCopy = '<table style="border: 1px solid black; width:100%; border-collapse: collapse; margin-bottom: 20px;"><thead><tr style="background-color: #ccc;">';
        this.columns.forEach(function (value, key) {
          tableCopy += '<th style="padding: 10px;">' + value.label + '</th>';
        });
        tableCopy += '</tr></thead>';
        //buat data dalam bentuk tbody dari variable table_items
        tableCopy += '<tbody>';
        if (this.table_items.length > 0) {
          let ix = 1;
          this.table_items.forEach(function (value, key) {
            tableCopy += '<tr>';
            tableCopy += '<td style="padding: 5px;">' + ix + '</td>';
            tableCopy += '<td style="padding: 5px;"></td>';
            tableCopy += '<td style="padding: 5px;">' + value.wmo + '</td>';
            tableCopy += '<td style="padding: 5px;">' + value.date_start + '</td>';
            tableCopy += '<td style="padding: 5px;">' + value.type + '</td>';
            tableCopy += '<td style="padding: 5px;">' + value.latitude + '</td>';
            tableCopy += '<td style="padding: 5px;">' + value.longitude + '</td>';
            ix++;
            tableCopy += '</tr>';
          });
        } else {
          tableCopy += '<tr><td colspan="' + this.columns.length + '" style="text-align:center;">No data available</td></tr>';
        }
        tableCopy += '</tbody>';
        tableCopy += '</table><br/>';
        //masukan style ke dalam table
        pdfContent.innerHTML = tableCopy;
      }
      if (this.IS_PRINT_MAPS_PETIR) {
        //masukan lmap ke dalam pdfContent
        const map = this.$refs.mymap.$el;
        const mapCopy = map.cloneNode(true);
        pdfContent.appendChild(mapCopy);
      }
      this.$refs.html2Pdf.generatePdf();
      this.showLoading = false;
      this.isLoading = false;
    },

    
    getReport(data) {
      // checkreport.id = id
      var token = jwt.getToken();
      const uri = helper.getBaseUrl() + `@get_file_integration?id=${data.id}`;
      console.log("uri : ", uri);
      var config = {
        method: "get",
        url: uri,
        headers: {
          Authorization: `Bearer  ${token}`,
        },
        responseType: "blob",
      };
      axios(config).then((response) => {
        console.log(response.headers["content-disposition"]);
        var raw_name_file = response.headers["content-disposition"];
        var namafile = raw_name_file.substring(22, parseInt(raw_name_file.length - 1));
        saveAs(response.data, namafile);
      });
    },

    onProgress(event) {},
    startPagination() {},
    hasPaginated() {},
    beforeDownload(event) {},
    hasDownloaded(event) {},
    modalShown() {
      setTimeout(() => {
        //mapObject is a property that is part of leaflet
        this.$refs.mymap.mapObject.invalidateSize(); 
      }, 100)
    },
    updateCoordinates(location) {
        console.log('drag marker');
        this.LATITUDE_SELECTED = location.latlng.lat;
        this.LONGITUDE_SELECTED = location.latlng.lng;
         
    },
    async ok(){
      let userLogin = JSON.parse(localStorage.getItem("userLogin"));
      let userId = userLogin.id;
      if (this.REPORT_SELECTED == "GCAM") {
        let produk = null;
        if(this.PRODUK_SELECTED.toLowerCase() == "forecast"){
          produk = "fc";
        } 
        if(this.PRODUK_SELECTED.toLowerCase() == "analisis"){
          produk = "an";
        }
        if(this.PRODUK_SELECTED == "" || this.DATE_RANGE_SELECTED.length == 0){
          this.$swal({
            title: "ERROR",
            text: "Silahkan lengkapi filter",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          return;
        }
        if(produk =='fc'){
          if(this.RANGE_START == "" || this.RANGE_END.length > 3){
            this.$swal({
              title: "ERROR",
              text: "Range Start tidak boleh kosong",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            return;
          }
          if(this.RANGE_END != "" && (this.RANGE_START > this.RANGE_END || this.RANGE_END.length > 3 || this.RANGE_END > 120)){
            this.$swal({
              title: "ERROR",
              text: "Range End tidak memenuhi kriteria",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            return;
          }
        }
        this.showLoading = true;
        this.isLoading = true;
        try{
          let product_api = '';
          if(this.PRODUK_SELECTED.toLowerCase() =="analisis"){
            product_api = "an";
          }
          if(this.PRODUK_SELECTED.toLowerCase() =="forecast"){
            product_api = "fc";
          } 
          if(this.TIME_SELECTED.toUpperCase() != "ALL"){
            this.TIME_SELECTED = this.TIME_SELECTED.slice(0,2);
          }
          let start = this.DATE_RANGE_SELECTED[0];
          let end = this.DATE_RANGE_SELECTED[1];
          const data_file = await outputintegrasi.getRegistrationGCAM(product_api, this.TIME_SELECTED,this.PARAMETER_SELECTED.toLowerCase(),this.RANGE_START,this.RANGE_END,start,end);
          if(data_file == null || data_file == ""){
            this.$swal({
              title: "ERROR",
              text: "Process Tidak Berhasil",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.showLoading = false;
            this.isLoading = false;
            return;
          }else{
            this.$swal({
              title: "SUCCESS",
              text: "Proccess Berhasil",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.table_items= [];
            this.fetchData(userId);
             this.showLoading = false;
            this.isLoading = false;
            return;
          }
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }
        
      }

      if (this.REPORT_SELECTED == "ECMWF") {
        let produk = null;
        if(this.PERIODE_SELECTED == "" || this.DATE_SELECTED =="" || this.NAMA_FILE_SELECTED == ""){
          this.$swal({
            title: "ERROR",
            text: "Silahkan lengkapi filter",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          this.showLoading = false;
          this.isLoading = false;
          return;
        }
      
        this.showLoading = true;
        this.isLoading = true;
        try{
          const data_ecmwf = await outputintegrasi.getRegistrationECMWF(this.PERIODE_SELECTED,this.NAMA_FILE_SELECTED,this.DATE_SELECTED);
          if(data_ecmwf == null || data_ecmwf == ""){
            this.$swal({
              title: "ERROR",
              text: "Process Tidak Berhasil",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            this.showLoading = false;
            this.isLoading = false;
            return;
          }else{
            this.$swal({
              title: "SUCCESS",
              text: "Proccess Berhasil",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.table_items= [];
            this.fetchData(userId);
             this.showLoading = false;
            this.isLoading = false;
            return;
          }
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }
        
      }

      if (this.REPORT_SELECTED == "PETIR") {
      
        if(this.SUMBER_SELECTED == "" || this.LATITUDE_SELECTED == "" || this.LONGITUDE_SELECTED == "" || this.RADIUS_SELECTED == "" || this.DATE_RANGE_SELECTED.length == 0){
          this.$swal({
            title: "ERROR",
            text: "Silahkan lengkapi filter",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
          return;
        }
        //jika sumber selected bukan db3 dan kml dan pijar
        if(this.SUMBER_SELECTED != "db3" && this.SUMBER_SELECTED != "kml" && this.SUMBER_SELECTED != "pijar"){
            this.$swal({
              title: "ERROR",
              text: "Silahkan lengkapi filter",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            return;
        }
        this.showLoading = true;
        this.csvfilename = "Data PETIR  "+this.start_time+" s/d "+this.end_time+".csv"
        this.isLoading = true;
        try{

          const {data} = await outputintegrasi.getDownloadPetir(this.SUMBER_SELECTED,this.DATE_RANGE_SELECTED[0],this.DATE_RANGE_SELECTED[1],this.LATITUDE_SELECTED,this.LONGITUDE_SELECTED,this.RADIUS_SELECTED);
          let petir = data.items;
          console.log("data petir : ",petir)
          var item_petir = [];
         
            this.columns = [
                  {
                    label: "No",
                    field: "no",
                  },
                  {
                    label: "Nama Stasiun",
                    field: "name",
                  },
                  {
                    label: "Stasiun ID",
                    field: "wmo",
                  },
                  {
                    label: "Datetime UTC",
                    field: "date_start",
                    dateInputFormat: "dd-mmmm-yyyy HH:mm:ss",
                    dateOutputFormat: "dd-mmmm-yyyy HH:mm:ss",
                  },
                  {
                    label: "Type of Strike",
                    field: "type",
                  },
                  {
                    label: "Latitude",
                    field: "latitude",
                  },
                  {
                    label: "Longitude",
                    field: "latitude",
                  },
                ];
            var nomor = 1;
           
            for(var i in petir){
              var row = petir[i]
                var data_petir = {}
                data_petir["no"] = nomor
                if (this.SUMBER_SELECTED.toLowerCase() == 'pijar'){
                  row.wmo = null;
                  row.type = null;

                }                
                  data_petir["name"] = this.LOCAL_WMO_LIST[row.wmo]
                  data_petir["wmo"] = row.wmo
                
                if(this.SUMBER_SELECTED.toLowerCase()=='db3' || this.SUMBER_SELECTED.toLowerCase()=='pijar'){
                  data_petir["date_start"] = row.datetime_utc.replace("T"," ")
                }
                if(this.SUMBER_SELECTED.toLowerCase()=='kml'){
                  data_petir["date_start"] = row.interval_start.replace("T"," ")
                }
                data_petir["type"] = row.type
                data_petir["latitude"] = row.latitude
                data_petir["longitude"] = row.longitude
                item_petir.push(data_petir)
                nomor++;
              // }                
            }
          
          if(item_petir.length > 0){
            this.downloadPetir = true;
          }
          this.table_items = item_petir;
           this.listMarker = item_petir;
          this.isLoading = false;
          this.showLoading = false;
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }
        
      }
      
      if (this.REPORT_SELECTED == "RADAR"){
        if (this.LOKASI_SELECTED == "" || this.PRODUK_SELECTED_RADAR == "" || this.DATE_START_SELECTED == "" || this.DATE_END_SELECTED == "" 
        || (this.PRODUK_SELECTED_RADAR != 'vol' && this.HOUR_START_SELECTED == "" || this.HOUR_END_SELECTED == "" || this.MINUTE_START_SELECTED == "" || this.MINUTE_END_SELECTED == "")) {       
              this.$swal({
                title: "ERROR",
                text: "Silahkan lengkapi filter",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
          return;
        }
        this.showLoading = true;
        this.isLoading = true;
         try{
          const data_radar = await outputintegrasi.getRegistrationRadar(this.LOKASI_SELECTED, this.PRODUK_SELECTED_RADAR, this.PARAMETER_SELECTED, this.DATE_START_SELECTED,this.DATE_END_SELECTED,this.HOUR_START_SELECTED,this.HOUR_END_SELECTED,this.MINUTE_START_SELECTED,this.MINUTE_END_SELECTED);
          if (data_radar == "" || data_radar == null) {
            this.$swal({
              title: "ERROR",
              text: "Proccess Tidak Berhasil",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
             this.showLoading = false;
            this.isLoading = false;
            return;
          }else{
            this.$swal({
              title: "SUCCESS",
              text: "Proccess Berhasil",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.table_items= [];
            this.fetchData(userId);
             this.showLoading = false;
            this.isLoading = false;
            return;
          }
          
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }
      }

      if (this.REPORT_SELECTED == "SATELIT"){
        if (this.TIPE_DATA_SELECTED == "" || this.PRODUK_SELECTED_SATELIT == "" || this.DATE_RANGE_SELECTED.length == 0 || this.CHANNEL_SELECTED == "" || this.FORMAT_DATA_SELECTED == "") {
              this.$swal({
                title: "ERROR",
                text: "Silahkan lengkapi filter",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
          return;
        }
        this.showLoading = true;
        this.isLoading = true;
         try{
          const data_satelit = await outputintegrasi.getRegistrationSatelit(this.TIPE_DATA_SELECTED, this.PRODUK_SELECTED_SATELIT, this.CHANNEL_SELECTED, this.FORMAT_DATA_SELECTED, this.DATE_RANGE_SELECTED[0],this.DATE_RANGE_SELECTED[1]);
          if (data_satelit == "" || data_satelit == null) {
            this.$swal({
              title: "ERROR",
              text: "Proccess Tidak Berhasil",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
             this.showLoading = false;
            this.isLoading = false;
            return;
          }else{
            this.$swal({
              title: "SUCCESS",
              text: "Proccess Berhasil",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.table_items= [];
            this.fetchData(userId);
             this.showLoading = false;
            this.isLoading = false;
            return;
          }
          
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }

      }

      if (this.REPORT_SELECTED == "RASON"){
        if (this.FILE_SELECTED == "" || this.WMO_SELECTED == "" || this.DATE_START_SELECTED == "" || this.DATE_END_SELECTED == "" || this.HOUR_START_SELECTED == "" || this.HOUR_END_SELECTED == "" || this.MINUTE_START_SELECTED == "" || this.MINUTE_END_SELECTED == "")  {       
              this.$swal({
                title: "ERROR",
                text: "Silahkan lengkapi filter",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
          return;
        }
        this.showLoading = true;
        this.isLoading = true;
         try{
          const data_rason = await outputintegrasi.getRegistrationRason(this.FILE_SELECTED, this.WMO_SELECTED, this.DATE_START_SELECTED,this.DATE_END_SELECTED,this.HOUR_START_SELECTED,this.HOUR_END_SELECTED,this.MINUTE_START_SELECTED,this.MINUTE_END_SELECTED);
          if (data_rason == "" || data_rason == null) {
            this.$swal({
              title: "ERROR",
              text: "Proccess Tidak Berhasil",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
             this.showLoading = false;
            this.isLoading = false;
            return;
          }else{
            this.$swal({
              title: "SUCCESS",
              text: "Proccess Berhasil",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.table_items= [];
            this.fetchData(userId);
             this.showLoading = false;
            this.isLoading = false;
            return;
          }
          
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }
      }
      if (this.REPORT_SELECTED == "FILE_RASON"){
        if (this.FILE_RASON_SELECTED == "" || this.WMO_SELECTED == "" || this.DATE_START_SELECTED == "" || this.DATE_END_SELECTED == "")  {       
              this.$swal({
                title: "ERROR",
                text: "Silahkan lengkapi filter",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-danger",
                },
                buttonsStyling: false,
              });
          return;
        }
        this.showLoading = true;
        this.isLoading = true;
         try{
          const data_rason = await outputintegrasi.getRegistrationFileRason(this.FILE_RASON_SELECTED, this.WMO_SELECTED, this.DATE_START_SELECTED,this.DATE_END_SELECTED);
          if (data_rason == "" || data_rason == null) {
            this.$swal({
              title: "ERROR",
              text: "Proccess Tidak Berhasil",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
             this.showLoading = false;
            this.isLoading = false;
            return;
          }else{
            this.$swal({
              title: "SUCCESS",
              text: "Proccess Berhasil",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.table_items= [];
            this.fetchData(userId);
             this.showLoading = false;
            this.isLoading = false;
            return;
          }
          
        }catch(e){
          this.isLoading = false;
          this.showLoading = false;
          console.log(e)
        }
      }

    },
    checkStarEndDate(datetime){
      var ds = moment(this.start_time, "YYYY-MM-DD HH:mm")
      var de = moment(this.end_time, "YYYY-MM-DD HH:mm");
      var a = ds.isBefore(de) // false
      var b = ds.isAfter(de)
      // console.log("a = "+a+" b = "+b)
      if(!a && b){
        if(datetime == "start"){
          this.start_time = "";
        }else if(datetime == "end"){
          this.end_time = ""
        }
        // alert("Bulan Akhir harus lebih besar/sama dari bulan "+ this.start_date)
        this.$swal({
            title: "ERROR",
            text: "Waktu Akhri harus sama/lebih besar dari Waktu awal ",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-danger",
            },
            buttonsStyling: false,
          });
      }
    },
    checkTableFormat() {
      if (this.REPORT_SELECTED == "PETIR") {
        this.columns = [
          {
            label: "No",
            field: "no",
          },
          {
            label: "Nama Stasun",
            field: "name",
          },
          {
            label: "Stasiun ID",
            field: "wmo",
          },
          {
            label: "Datetime UTC",
            field: "date_start",
            dateInputFormat: "dd-mmmm-yyyy HH:mm:ss",
            dateOutputFormat: "dd mmmm yyyy",
          },
          {
            label: "Type of Strike",
            field: "type",
          },
          {
            label: "Latitude",
            field: "latitude",
          },
          {
            label: "Longitude",
            field: "latitude",
          },
          // {
          //   label: "Download",
          //   field: "download",
          // },
        ];
      }else if (this.REPORT_SELECTED == 'SATELIT' 
      || this.REPORT_SELECTED == 'RADAR'
      || this.REPORT_SELECTED == 'GCAM'
      || this.REPORT_SELECTED == 'ECMWF'
      || this.REPORT_SELECTED == 'RASON'
      ){
          this.columns = [
            {
              label: "Id",
              field: "id",
            },
            
            {
              label: "Create",
              field: "at_create",
            },
            {
              label:"Status",
              field:"status"
            },
            {
              label: "Download",
              field: "download",
            },
          ];
      }
    },
    toTitleCase(str) {
      const arr = str.split("_");
      const result = [];
      for (const word of arr) {
        result.push(word.charAt(0).toUpperCase() + word.slice(1));
      }
      return result.join(" ");
    },


    onChangeRangeDate(date, dateString) {
      this.DATE_RANGE_SELECTED = dateString;
    },
    onChangeDate(date, dateString) {
      this.DATE_SELECTED = dateString;
    },
    onChangeDateStart(date, dateString) {
      this.DATE_START_SELECTED = dateString;
    },
    onChangeDateEnd(date, dateString) {
      this.DATE_END_SELECTED = dateString;
    },
    onChangeDatePeriode(date, dateString) {
      this.DATE_PERIODE_SELECTED = dateString;
    },
     onChangeWeekPeriode(date, dateString) {
      this.DATE_PERIODE_SELECTED = moment(dateString,'YYYY-W');
      //call api outputintegrasi.getDownloadOutput
      this.getDownloadOutput();

    },
    onChangeWMO(values) {
      this.WMO_SELECTED = values;
    },
    onChangeSUMBER(values) {
      this.SUMBER_SELECTED = values;
    },
    onChangeFile(values) {
      this.FILE_SELECTED = values;
    },
    onChangeFileRason(values) {
      this.FILE_RASON_SELECTED = values;
    },
    onChangeFileRawDataPetir(values) {
      this.RAW_DATA_PETIR_SELECTED = values;
    },
    onChangeLATITUDE(values) {
      this.LATITUDE_SELECTED = values;
    },
    onChangeLONGITUDE(values) {
      this.LONGITUDE_SELECTED = values;
    },
    onChangeRADIUS(values) {
      this.RADIUS_SELECTED = values;
    },
    onChangeStation(values) {
      this.STATION_SELECTED = values;
    },
    onChangePeriodeList(values) {
      this.NAMA_FILE_SELECTED = null;
      this.DATE_SELECTED = null;
      if (values.toLowerCase() == 'pekanan'){
        this.NAMA_FILE_LIST = [
          {value: 'D1F', label: 'D1F'}
        ]
        this.PARAMETER_SELECTED = 'D1F';
      }
      if (values.toLowerCase() == 'harian'){
        this.NAMA_FILE_LIST = null;
        this.NAMA_FILE_LIST = [
          {value:'D1D',label:'D1D'},
          {value:'D1E',label:'D1E'},
          {value:'D1Y',label:'D1Y'},
        ]
      }
      this.PERIODE_SELECTED = values;
    },
     onChangeProdukList(values) {
      this.PARAMETER_SELECTED = null;
      if (values.toLowerCase() == 'analisis'){
        this.PARAMETER_LIST = [
          {value: 'gtco3', label: 'CO3'}
        ]
        this.PARAMETER_SELECTED = 'gtco3';
      }
      if (values.toLowerCase() == 'forecast'){
        this.PARAMETER_LIST = null;
        this.PARAMETER_LIST = [
          {value:'gtco3',label:'CO3'},
          {value:'pm1',label:'PM1'},
          {value:'pm2p5',label:'PM2.5'},
          {value:'pm10',label:'PM10'},
          {value:'uvbed',label:'UVbed'}
        ]
        this.PARAMETER_SELECTED = 'gtco3';
      }
      this.PRODUK_SELECTED = values;
    },
    onChangeLokasi(values) {
      
      this.LOKASI_SELECTED = values;
      this.PRODUK_SELECTED_RADAR = null;
       this.PRODUK_RADAR_LIST = [
          {value:'vol',label:'VOL'},
          {value:'tif',label:'TIF'},
          {value:'png',label:'PNG'},
        ]
    },
    onChangeHourStart(values) {
      this.HOUR_START_SELECTED = values;
    },
    onChangeMinuteStart(values) {
      this.MINUTE_START_SELECTED = values;
    },
    onChangeHourEnd(values) {
      this.HOUR_END_SELECTED = values;
    },
    onChangeMinuteEnd(values) {
      this.MINUTE_END_SELECTED = values;
    },
    onChangeTipeData(values) {
      this.TIPE_DATA_SELECTED = values;
    },
    onChangeProdukRadar(values){
        this.PARAMETER_LIST = [
          {value:"PAC24H",label:"PAC24H"},
          {value:"CMAX",label:"CMAX"}
        ];
      this.PRODUK_SELECTED_RADAR = values;

    },
    onChangeProdukSatelit(values) {
      this.PRODUK_SELECTED_SATELIT = values;
    },
    onChangeChannel(values) {
      this.CHANNEL_SELECTED = values;
    },
    onChangeFormatData(values) {
      this.FORMAT_DATA_SELECTED = values;
    },
    onChangeTIME(values) {
      this.TIME_SELECTED = values;
    },
    onChangeRangeStart(values) {
      this.RANGE_START = values;
    },
    onChangeRangeEnd(values) {
      this.RANGE_END = values;
    },
    onChangeParameterList(values) {
      this.PARAMETER_SELECTED = values;
      console.log(this.PARAMETER_SELECTED);
    },
    onChangeJENISTANAH(values) {
      this.JENIS_TANAH_SELECT = values;
    },
    onChangeMONTH(values) {
      this.MONTH_SELECTED = values;
    },
    onYearsPicker() {
      return [];
    },
    onChangeYearStart(values) {
      if (Number(values) > Number(this.END_YEARS_SELECTED)) {
        this.END_YEARS_SELECTED = Number(values);
      }
      this.START_YEARS_SELECTED = Number(values);
    },
    onChangeYearEnd(values) {
      if (Number(values) < Number(this.START_YEARS_SELECTED)) {
        this.START_YEARS_SELECTED = Number(values);
      }
      this.END_YEARS_SELECTED = Number(values);
    },
    async onSelectedMenu(node) {
       let userLogin = JSON.parse(localStorage.getItem("userLogin"));
      let userId = userLogin.id;
      console.log('ubah menu');
      this.REPORT_SELECTED = null;
      this.spinningLoadingPage = true;
      this.isLoading = true;
      this.WMO_SELECTED = "";
      this.DATE_PERIODE_SELECTED = "";
      this.DATE_RANGE_SELECTED = null;
      this.DATE_SELECTED = "";
      this.DATE_START_SELECTED = "";
      this.DATE_END_SELECTED = "";
      this.STATION_SELECTED = "";
      this.STORE_WMO = [];
      this.STORE_REPORT = {};
      this.TIME_SELECTED = "";
      this.RANGE_START = "";
      this.RANGE_END = "";
      this.PARAMETER_SELECTED = "";
      this.PERIODE_SELECTED = "";
      this.PRODUK_SELECTED = "";
      this.TIPE_DATA_SELECTED = "";
      this.FORMAT_DATA_SELECTED = "";
      this.SUMBER_SELECTED = "";
      this.FILE_SELECTED = null;
      this.FILE_RASON_SELECTED = null;
      this.RAW_DATA_PETIR_SELECTED = null;
      this.PRODUK_SELECTED_SATELIT = "";
      this.PRODUK_SELECTED_RADAR = "";
      if(node.model.unix_code == 'RADAR'){
        this.PARAMETER_LIST = [];
      }

      this.CHANNEL_SELECTED = null;
      this.LOKASI_SELECTED = null;
      this.HOUR_START_SELECTED = null;
      this.HOUR_END_SELECTED = null;
      this.MINUTE_START_SELECTED = null;
      this.MINUTE_END_SELECTED = null;
      this.DATE_SELECTED = "";
      this.DATE_START_SELECTED = "";
      this.DATE_END_SELECTED = "";
      this.DATE_RANGE_SELECTED = "";
      this.END_YEARS_SELECTED = "";
      this.START_YEARS_SELECTED = "";
      this.LATITUDE_SELECTED= -6.93,
      this.LONGITUDE_SELECTED= 107.60,
      this.RADIUS_SELECTED = "";
      this.isViewTable = true;
      this.IS_PRINT_TABLE_PETIR = true;
      this.IS_PRINT_MAPS_PETIR = false;
      this.downloadPetir = false;
      console.log("node.model.unix_code 1 :",node.model.unix_code)
      if (this.ALLOW_DATE_RANGE_PICKER_YEARS.indexOf(node.model.unix_code) != -1) {
        this.onYearsStart();
        this.onYearsEnd();
      }
      // console.log("node.model.unix_code ", node.model.unix_code);
      console.log("node.model.unix_code 2:",node.model.unix_code)
      if (this.ALLOW_WMO_PICKER.indexOf(node.model.unix_code) != -1) {
        this.fetching = true;
        this.showLoading = true;
        new Promise(async (resolve) => {
          this.STORE_WMO = await DataWMO.callDataWMOIntegrasi(node.model.unix_code);
          this.STORE_WMO_SELECT = this.STORE_WMO;
          var list_wmo_local = {}
          for(var i in this.STORE_WMO){
            var row = this.STORE_WMO[i]
            list_wmo_local[row.station_wmo_id] = row.station_name
          }
          this.LOCAL_WMO_LIST = list_wmo_local
          localStorage.setItem("list_station_wmo", JSON.stringify(this.STORE_WMO));

          this.spinningLoadingPage = false;
          this.showLoading = false;
          resolve(this.STORE_WMO);
        });

      }
      this.spinningLoadingPage = false;

      if (node.model.code == "MENU_ITEM") {
        this.REPORT_SELECTED = node.model.unix_code;
        this.checkTableFormat();
        this.isLoading = false;
        this.table_items= [];
        this.fetchData(userId);
      }
    },
    serviceTableKah(){
      
    },

    fetchWmo(search) {
      this.fetching = true;
      let temp = search.toLowerCase();
      var foundRaw = [];
      var WmoStore = this.STORE_WMO;
      if (search == "" || search == null) {
        this.STORE_WMO_SELECT = this.STORE_WMO;
      }
      for (var i in WmoStore) {
        var stationName = WmoStore[i].station_name.toString().toLowerCase().search(temp);
        var wmoID = WmoStore[i].station_wmo_id.toString().toLowerCase().search(temp);
        if (stationName != -1 || wmoID != -1) {
          foundRaw.push(WmoStore[i]);
        }
      }
      this.STORE_WMO_SELECT = foundRaw;
      this.fetching = false;
    },
    fetchStation(search) {
      this.SEARCH_STATION = search;
      this.fetching = true;
      this.STORE_STATION_SELECT = [];
      let temp = search.toLowerCase();
      var foundRaw = [];
      var StationStore = this.STORE_STATION;
      if (search == "" || search == null) {
        var no = 1;
        var dataStation = [];
        for (var i in StationStore) {
          dataStation.push(StationStore[i]);
          if (no == 300) {
            break;
          }
          no++;
        }
        this.STORE_STATION_SELECT = dataStation;
      }

      var noSearch = 1;
      for (var i in StationStore) {
        if (StationStore[i].station_name != null) {
          var stationName = StationStore[i].station_name.toString().toLowerCase().search(temp);
          var stationID = StationStore[i]["station_id"].toString().toLowerCase().search(temp);
          if (stationName != -1 || stationID != -1) {
            foundRaw.push(StationStore[i]);
            noSearch++;
          }
          if (noSearch == 300) {
            break;
          }
        }
      }
      this.STORE_STATION_SELECT = foundRaw;
      this.fetching = false;
    },
    onDropdownStation(event) {
      console.log(event);
    },
    contextSelectedTree() {},
    onYearsStart() {
      this.START_YEARS_STORE = [];
      var DateNow = new Date();
      for (var i = Number(DateNow.getFullYear()); i > Number(DateNow.getFullYear() - 30); i--) {
        this.START_YEARS_STORE.push(i);
      }
    },
    onYearsEnd() {
      this.END_YEARS_STORE = [];
      var DateNow = new Date();
      for (var i = Number(DateNow.getFullYear()); i > Number(DateNow.getFullYear() - 30); i--) {
        this.END_YEARS_STORE.push(i);
      }
    },
    //Tambahan => Table
    // Id, report (ME), period, status, button
    
    
    beforeDownload($event) {},
    onProgress($event) {},
    hasStartedGeneration() {},
    hasGenerated($event) {},
    moment,
  },
  watch: {
    start_time(val){
      this.checkStarEndDate("start")
    },
    end_time(val){
      this.checkStarEndDate("end")
    },
  },
  data() {
    return {
    pdfStyles: `
      @media print {
        @page {
          margin: 10cm;
        }
      }
      `,
      listMarker:[],
      icon: icon({
        // iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        //iconUrl from this path marker
        iconUrl: require("@/assets/images/marker/green-maps-icon.png"),
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      url:'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:'© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 12,
      center: [-6.93,107.60],
      bounds: null,
      pickerOptions: {
        disabledDate(time) {
          return new Date(time).getDay() === 1;
        }
      },
      escapeCsv: false,
      downloadPetir:false,
      csvfilename:"",
      showLoading:false,
      LOCAL_WMO_LIST :{},
      ROWS_DATA_REPORT_KAH : {},
      TABLE_REPORT_KAH : [],
      form_ku:false,
      isLoading: false,
      pageLength: 10,
      setIntervalCheckReport: null,
      resultreport: {},
      checkreport: [],
      blockCount: false,
      periodeHide: false,
      dateHide: false,
      reportType: null,
      checkStatus: null,
      REPORT_ORIENTATION: "landscape",
      DETAIL_WMO_SELECT: {},
      DETAIL_STATION_SELECT: {},
      ALLOW_DATE_PICKER: ["ECMWF"],
      ALLOW_WEEK_PICKER:['ECMWF'],
      ALLOW_SUMBER_PETIR:["PETIR"],
      ALLOW_FILE_OPTION : ["RASON"],
      ALLOW_FILE_RASON_OPTION : ["FILE_RASON"],
      ALLOW_RAW_DATA_PETIR_OPTION : ["RAW_DATA_PETIR"],
      ALLOW_START_DATETIME:["PETIR"],
      ALLOW_DATE_START_PICKER : ["FILE_RASON","RAW_DATA_PETIR"],
      ALLOW_DATE_END_PICKER : ["FILE_RASON","RAW_DATA_PETIR"],
      ALLOW_DATE_TIME_START_PICKER : ["RADAR","RASON"],
      ALLOW_DATE_TIME_END_PICKER : ["RADAR","RASON"],
      HOUR_START_SELECTED : null,
      MINUTES_START_SELECTED : null,
      HOUR_END_SELECTED : null,
      MINUTES_END_SELECTED : null,
      SUMBER_SELECTED :"",
      FILE_SELECTED : "",
      FILE_RASON_SELECTED : "",
      RAW_DATA_PETIR_SELECTED : "",
      SUMBER_LIST : [
          {value:'db3',label:'DB3'},
         {value:'kml',label:'KML'},
         {value:'pijar',label:'Pijar'},
      ],
      FILE_LIST : [
        {value:'BUFR',label:'BUFR'},
        {value:'LOG',label:'LOG'},
      
      ],
      FILE_RASON_LIST : [
        {value:'BUFR',label:'BUFR'},
        {value:'LOG',label:'LOG'},
        {value: 'CSV',label:'CSV'},
        {value:'GTS',label:'GTS'},
        {value:'OBS',label:'OBS'},
      ],
      RAW_DATAP_ETIR_LIST : [
        {value:'LDC',label:'LDC'},
        {value:'KML',label:'KML'},
        {value: 'TXT',label:'TXT'},
        {value:'NEX',label:'NEX'},
        {value:'DB3',label:'DB3'},
      ],
      PERIODE_LIST:[
        {value:'Harian',label:'Harian'},
         {value:'Pekanan',label:'Pekanan'}
        
        ],
      PERIODE_SELECTED: null,
      PRODUK_LIST:[
        {value:'Analisis',label:'Analisis'},
        {value:'Forecast',label:'Forecast'}
      ],
      CHANNEL_LIST:{
        rawHimawariCloud: [
            { value: "B01", label: "B01", id: 1 },
            { value: "B02", label: "B02", id: 2 },
            { value: "B03", label: "B03", id: 3 },
            { value: "B04", label: "B04", id: 4 },
            { value: "B05", label: "B05", id: 5 },
            { value: "B06", label: "B06", id: 6 },
            { value: "B07", label: "B07", id: 7 },
            { value: "B08", label: "B08", id: 8 },
            { value: "B09", label: "B09", id: 9 },
            { value: "B10", label: "B10", id: 10 },
            { value: "B11", label: "B11", id: 11 },
            { value: "B12", label: "B12", id: 12 },
            { value: "B13", label: "B13", id: 13 },
            { value: "B14", label: "B14", id: 14 },
            { value: "B15", label: "B15", id: 15 },
            { value: "B16", label: "B16", id: 16 },
        ],
        rawHimawariWis: [
            { value: "GS", label: "GS", id: 17 },
            { value: "I2", label: "I2", id: 18 },
            { value: "I4", label: "I4", id: 19 },
            { value: "IR", label: "IR", id: 20 },
            { value: "VS", label: "VS", id: 21 },
            { value: "WV", label: "WV", id: 22 },
        ],
        rawHCAI: [
            { value: "HCAI", label: "raw data HCAI", id: 23 },
        ],
        rawGSMap: [
            { value: "rawGSMap", label: "raw data GSMap", id: 24 },
        ],
        rawHotspot: [
            { value: "rawHotspot", label: "raw data Hotspot", id: 25 },
        ],
        rawRDCA: [
            { value: "rdca", label: "raw data RDCA", id: 26 },
        ],
        imageHimawari8: [
            { value: "EH", label: "EH - IR Enhanced", id: 23 },
            { value: "NC", label: "NC - Natural Color", id: 24 },
            { value: "WE", label: "WE - Water Vapor Enhanced", id: 25 },
            { value: "RP", label: "RP - Rainfall Potential", id: 26 },
            { value: "SW", label: "SW - Sandwich", id: 28 },
            { value: "RD", label: "RD - RDCA", id: 29 },
            { value: "IW", label: "IW - Infrared Wind", id: 30 },
        ],
        imageHCAI: [
            { value: "CLC", label: "CLC - Cloud Type", id: 31 },
            { value: "HTC", label: "HTC - Cloud Top Height", id: 32 },
            { value: "CVC", label: "CVC - Convective Cloud Coverage", id: 33 },
        ],
        imageGSMap: [
            { value: "Hourly", label: "GSMap Rainrate Hourly", id: 34 },
            { value: "Precipitation_24hr", label: "GSMap Rainrate Daily", id: 35 },
            { value: "MonthlyAverage", label: "GSMap Rainrate Monthly", id: 36 },
            { value: "HTH", label: "GSMap Consecutive Dry Days", id: 37 },
        ],
        imageHotspot: [
            { value: "Hotspot", label: "Polar Hotspot", id: 38 },
        ],

      },
      // FORMATA_DATA_LIST:[],
      FORMAT_DATA_LIST:{
        B01: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B02: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B03: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B04: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B05: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B06: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B07: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B08: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B09: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B10: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B11: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B12: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B13: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B14: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B15: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        B16: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        GS: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        I2: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        I4: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        IR: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        VS: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        WV: [
            { value: ".Z", label: "SATAID", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        HCAI: [
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        rawGSMap: [
            { value: ".dat", label: "Dat", id: 1 },
            { value: ".nc", label: "NetCDF", id: 2 },
        ],
        rawHotspot: [
            { value: ".txt", label: "TXT", id: 2 },
        ],
        rdca: [
            { value: ".txt", label: "TXT", id: 2 },
        ],
        EH: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        NC: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        WE: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        RP: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        SW: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        RD: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        IW: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        CLC: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        HTC: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        CVC: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        Hourly: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        Precipitation_24hr: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        MonthlyAverage: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        HTH: [
            { value: ".png", label: "PNG", id: 2 },
        ],
        Hotspot: [
            { value: ".png", label: "PNG", id: 2 },
        ]
      },
      FORMAT_DATA_SELECTED: null,
      PRODUK_SATELIT_LIST:{
         raw: [
            { value: "rawHimawariCloud", label: "Himawari Cloud", id: 1 },
            { value: "rawHimawariWis", label: "Himawari Wis", id: 2 },
            { value: "rawHCAI", label: "HCAI", id: 3 },
            { value: "rawGSMap", label: "GSMap", id: 4 },
            { value: "rawHotspot", label: "Polar Hotspot", id: 5 },
            { value: "rawRDCA", label: "RDCA", id: 6 },
        ],
        image: [
            { value: "imageHimawari8", label: "Himawari 8", id: 7 },
            { value: "imageHCAI", label: "HCAI", id: 8 },
            { value: "imageGSMap", label: "GSMap", id: 9 },
            { value: "imageHotspot", label: "Polar Hotspot", id: 10 },
        ]
      },
      PRODUK_RADAR_LIST:[],
      LOKASI_SELECTED: null,
      LOKASI_LIST:[
          { value: "ACE", label: "ACE - ACEH" },
          { value: "MND", label: "MND - MANDALIKA" },
          { value: "PDG", label: "PDG - PADANG" },
          { value: "SBY", label: "SBY - SURABAYA" },
          { value: "BIK", label: "BIK - BIAK" },
          { value: "LPG", label: "LPG - LOMBOK" },
          { value: "PON", label: "PON - PONTIANAK" },
          { value: "DEN", label: "DEN - DENPASAR" },
          { value: "KPG", label: "KPG - KUPANG" },
          { value: "SMG", label: "SMG - SEMARANG" },
          { value: "JAK", label: "JAK - JAKARTA" },
          { value: "BAL", label: "BAL - BALIKPAPAN" },
          { value: "BMA", label: "BMA - BANDUNG" },
          { value: "MED", label: "MED - MEDAN" },
          { value: "PLB", label: "PLB - PALEMBANG" },
          { value: "JAY", label: "JAY - JAYAPURA" },
          { value: "AMQ", label: "AMQ - AMPENA" },
          { value: "MRS", label: "MRS - MERAUKE" },
          { value: "PKY", label: "PKY - PALANGKARAYA" },
          { value: "BAN", label: "BAN - BANDAR LAMPUNG" },
          { value: "BKS", label: "BKS - BENGKULU" },
          { value: "GOR", label: "GOR - GORONTALO" },
          { value: "DJB", label: "DJB - JAMBI" },
          { value: "TAR", label: "TAR - TARAKAN" },
          { value: "TTE", label: "TTE - TERNATE" },
          { value: "CLP", label: "CLP - CILACAP" },
          { value: "MER", label: "MER - MERAK" },
          { value: "SOR", label: "SOR - SORONG" },
          { value: "KEN", label: "KEN - KENDARI" },
          { value: "LOP", label: "LOP - LOMBOK" },
          { value: "PKP", label: "PKP - PANGKALPINANG" },
          { value: "TIM", label: "TIM - TIMIKA" },
          { value: "PKN", label: "PKN - PANGKALANBUN" },
          { value: "YOG", label: "YOG - YOGYAKARTA" },
          { value: "PAL", label: "PAL - PALU" },
          { value: "GNS", label: "GNS - GUNUNG SITOLI" },
          { value: "MAJ", label: "MAJ - MAJENE" },
          { value: "MAS", label: "MAS - MASAMBA" },
          {value:"MAU",label:"MAU - MAUMERE"},
          {value:"SIN",label:"SIN - SINTANG"},
          {value:"WAM",label:"WAM - WAHAI"},
        ],
        HOUR_LIST: [
        {value:"00",label:"00"},
        {value:"01",label:"01"},
        {value:"02",label:"02"},
        {value:"03",label:"03"},
        {value:"04",label:"04"},
        {value:"05",label:"05"},
        {value:"06",label:"06"},
        {value:"07",label:"07"},
        {value:"08",label:"08"},
        {value:"09",label:"09"},
        {value:"10",label:"10"},
        {value:"11",label:"11"},
        {value:"12",label:"12"},
        {value:"13",label:"13"},
        {value:"14",label:"14"},
        {value:"15",label:"15"},
        {value:"16",label:"16"},
        {value:"17",label:"17"},
        {value:"18",label:"18"},
        {value:"19",label:"19"},
        {value:"20",label:"20"},
        {value:"21",label:"21"},
        {value:"22",label:"22"},
        {value:"23",label:"23"},
      ],
      MINUTE_LIST:[
        { value: "00", label: "00" },
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
        { value: "50", label: "50" },
        { value: "59", label: "59" },
      ],
      TIPE_DATA_LIST:[
        { value: "raw", label: "Raw Data", id: 1 },
        { value: "image", label: "Product Image", id: 2 }

      ],
      PRODUK_SELECTED: null,
      PRODUK_SELECTED_RADAR: null,
      PRODUK_SELECTED_SATELIT:null,
      TIPE_DATA_SELECTED: null,
      CHANNEL_SELECTED: null,
      RANGE_START:null,
      RANGE_END:null,
      ALLOW_CHANNEL_PICKER: ["SATELIT"],      
      ALLOW_FORMAT_DATA_PICKER: ["SATELIT"],
      ALLOW_TIPE_DATA_PICKER: ["SATELIT"],
      ALLOW_PRODUK_SATELIT_PICKER: ["SATELIT"],
      ALLOW_PRODUK_RADAR_PICKER: ["RADAR"],
      ALLOW_PRODUK_LIST_PICKER: ["GCAM"],
      ALLOW_NAMA_FILE_LIST_PICKER: ["ECMWF"],
      STORE_TIME: ["ALL","00:00", "12:00"],
      INITIAL_TIME: ["00:00"],
      ALLOW_PERIODE_LIST_PICKER: ["ECMWF"],
      ALLOW_LOKASI_PICKER: ["RADAR"],
      ALLOW_DATE_RANGE_PICKER: ["PETIR","GCAM", "SATELIT"],
      ALLOW_DATE_PICKER_MONTH: ["ACS"],
      ALLOW_DATE_RANGE_PICKER_YEARS: ["ACS","KIMIA_AIR_HUJAN"],
      ALLOW_PICKER_YEARS : ["KIMIA_AIR_HUJAN"],
      ALLOW_LATITUDE_PICKER: ["PETIR"],
      ALLOW_LONGITUDE_PICKER: ["PETIR"],
      ALLOW_RADIUS_PICKER: ["PETIR"],
      ALLOW_MAPS_PICKER: ["PETIR"],
      ALLOW_WMO_PICKER: ["RASON","FILE_RASON","RAW_DATA_PETIR"],
      ALLOW_PARAMETER: ["GCAM","RADAR"],
      ALLOW_TIME: ['GCAM'],
      ALLOW_TIME_00: ['GCAM'],
      ALLOW_JENIS_TANAH: ["LYSIMETER"],
      REPORT_SELECTED: "",
      DATE_PERIODE_SELECTED: "",
      DATE_RANGE_SELECTED: [],
      LATITUDE_SELECTED: -6.93,
      LONGITUDE_SELECTED: 107.60,
      RADIUS_SELECTED: null,
      IS_PRINT_MAPS_PETIR: false,
      IS_PRINT_TABLE_PETIR: true,
      DATE_START_SELECTED: "",
      DATE_END_SELECTED: "",
      DATE_SELECTED: "",
      MONTH_SELECTED: "",
      YEAR_SELECTED: "",
      START_YEARS_SELECTED: "",
      END_YEARS_SELECTED: "",
      WMO_SELECTED: "",
      STATION_SELECTED: "",
      TIME_SELECTED: "",
      JENIS_TANAH_SELECT: "",
      STORE_WMO: [],
      STORE_STATION: [],
      STORE_REPORT: {},
      STORE_WMO_SELECT: [],
      STORE_STATION_SELECT: [],
      PARAMETER_SELECTED: null,
      PARAMETER_LIST:[
          {value:'gtco3',label:'CO3'},
          {value:'pm1',label:'PM1'},
          {value:'pm2p5',label:'PM2.5'},
          {value:'pm10',label:'PM10'},
          {value:'uvbed',label:'UVbed'}
      ],
      NAMA_FILE_SELECTED: null,
      NAMA_FILE_LIST:[
          {value:'D1D',label:'D1D'},
          {value:'D1E',label:'D1E'},
          {value:'D1Y',label:'D1Y'},
      ],
      dpconfig: {
          enableTime: true,
          dateFormat: "Y-m-d H:i",
      },
      table_items:[],
      start_time:"",
      end_time:"",
      START_YEARS_STORE: [],
      END_YEARS_STORE: [],
      STORAGE_MONTH: [
        { KEY: "01", LABEL: "JANUARY" },
        { KEY: "02", LABEL: "FEBRUARI" },
        { KEY: "03", LABEL: "MARET" },
        { KEY: "04", LABEL: "APRIL" },
        { KEY: "05", LABEL: "MEI" },
        { KEY: "06", LABEL: "JUNI" },
        { KEY: "07", LABEL: "JULI" },
        { KEY: "08", LABEL: "AGUSTUS" },
        { KEY: "09", LABEL: "SEPTEMBER" },
        { KEY: "10", LABEL: "OKTOBER" },
        { KEY: "11", LABEL: "NOVEMBER" },
        { KEY: "12", LABEL: "DESEMBER" },
      ],
      spinningLoadingPage: false,

      openAll: true,
      fetching: false,
      spinningLoading: false,
      treeTypes: [
        {
          type: "#",
          max_children: 6,
          max_depth: 4,
        },
        {
          type: "Report",
          icon: "far fa-folder",
        },
        {
          type: "Basic",
          icon: "far fa-compass",
        },
        {
          type: "Top-up",
          icon: "far fa-minus-square",
        },
      ],
      treeData: [
        {
          id: 0,
          text: "Integrasi",
          type: "Report",
          count: 0,
          children: [
            {
              id: 1,
              code: "GEOFISIKA",
              unix_code: "GEOFISIKA",
              text: "Geofisika",
              type: "Basic",
              count: 6,
              children: [
                {
                  id: 2,
                  unix_code: "PETIR",
                  code: "MENU_ITEM",
                  text: "Petir",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 3,
                  unix_code: "MAGNET",
                  code: "MENU_ITEM",
                  text: "Magnet",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 4,
                  unix_code: "ACCELEROGRAPH",
                  code: "MENU_ITEM",
                  text: "AcceleroGraph",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                id: 5,
                unix_code : "WAVEFORM_SEISMOGRAPH",
                code:"MENU_ITEM",
                text: "Waveform Seismograph",
                type: "Top-up",
                count: 0,
                children: [],
                },
              ],
            },
            {
              id: 6,
              code: "METEOROLOGI",
              unix_code: "METEOROLOGI",
              text: "Meteorologi",
              type: "Basic",
              count: 6,
              children: [
                {
                  id: 7,
                  unix_code: "SATELIT",
                  code: "MENU_ITEM",
                  text: "Satelit",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 8,
                  unix_code: "RADAR",
                  code: "MENU_ITEM",
                  text: "Radar",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 9,
                  unix_code: "ECMWF",
                  code: "MENU_ITEM",
                  text: "ECMWF",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 10,
                  unix_code: "GCAM",
                  code: "MENU_ITEM",
                  text: "GCAM",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 11,
                  unix_code: "UPPERAIR",
                  code: "MENU_ITEM",
                  text: "Upperair",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 12,
                  unix_code: "RASON",
                  code: "MENU_ITEM",
                  text: "Rason",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                {
                  id: 13,
                  unix_code: "FILE_RASON",
                  code: "MENU_ITEM",
                  text: "File Rason",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
                 {
                  id: 14,
                  unix_code: "RAW_DATA_PETIR",
                  code: "MENU_ITEM",
                  text: "Raw Data Petir",
                  type: "Top-up",
                  count: 0,
                  children: [],
                },
              ],
            },
          ],
        },
      ],
      contextItems: [],
      selectedNode: null,

      // Id, report (ME), period, status, button
      checkreport_ku : [],
      columns: [
        // {
        //   label: "Id",
        //   field: "id",
        // },
        {
          label: "No",
          field: "no",
        },
        {
          label: "Jenis Report",
          field: "name",
          // type: "number",
        },
        {
          label: "Stasiun ",
          field: "wmo",
          // type: "number",
        },
        {
          label: "Date Start",
          field: "date_start",
          // type: "date",
          dateInputFormat: "dd-mmmm-yyyy",
          dateOutputFormat: "dd mmmm yyyy",
        },
        {
          label: "Date End",
          field: "date_end",
          // type: "date",
          dateInputFormat: "dd-mmmm-yyyy",
          dateOutputFormat: "dd mmmm yyyy",
        },
        {
          label: "Periode",
          field: "periode",
          // filterable: true,
        },
        {
          label: "Created",
          field: "created_at",
        },
        {
          label: "Status",
          field: "status_download",
          // type: "percentage",
        },
        {
          label: "Download",
          field: "download_status",
          filterable: true,
        },
      ],
      rows: [
        {
          id: 1,
          report: "ME 45",
          date: "2022-07-29",
          status: "100%",
          details: true,
        },
        {
          id: 2,
          report: "ME 48",
          date: "28 Juli 2022",
          status: "20%",
          details: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style>
.vgt-table thead th.theadc {
  background-color: #f7fafc !important;
}

table tbody tr td {
  color: #000;
}

.monitor table.vgt-table td {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor .vgt-left-align {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor table.vgt-table {
  font-size: 12px !important;
}

.button-download {
  padding: 5px;
  border: 1px solid #ddd;
  color: #fff;
  background-color: green;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  font-family: "微软雅黑", arail;
  cursor: pointer;
}

button:hover {
  background-color: #fff;
  color: green;
  border: 1px solid green;
}

#modalReport {
  top: 100px;
  /* left: -10px !important; */
  /* background-color: yellow !important; */
}
</style>
