import axios from "axios";
import jwt from "@/auth/jwt/useJwt";
import Helper from "@/api/helper"
import moment from "moment";
import FileSaver from "file-saver";
export default {
 
  
  async getDownloadPetir(source,date_start,date_end,lat,lng,radius){
      let start_time = date_start+'T00:00:00';
      let end_time = date_end+ 'T23:59:00';
      let url = "";
        if(source.toLowerCase() == "db3"){
            url = Helper.getBaseUrl()+"/@get_db3?data_timestamp__gte="+start_time+"&data_timestamp__lte="+end_time+"&lat="+lat+"&lon="+lng+"&radius="+radius;
        }
        if(source.toLowerCase() == "kml"){
            url = Helper.getBaseUrl()+"/@get_kml?data_timestamp__gte="+start_time+"&data_timestamp__lte="+end_time+"&lat="+lat+"&lon="+lng+"&radius="+radius;
        }
        if(source.toLowerCase() == "pijar"){
          url = Helper.getBaseUrl()+"@get_pijar?data_timestamp__gte="+start_time+"&data_timestamp__lte="+end_time+"&lat="+lat+"&lon="+lng+"&radius="+radius;
      }
      let axi =  await axios.get(url, Helper.getConfig());

      return axi;
  },
  


  async getRegistrationGCAM(product,initial, parameter, range_start, range_end = null, data_timestamp__gte = null, data_timestamp__lte = null) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    try {
      let url = Helper.getBaseUrl()+"/@register_integration?service=gcam&user_id=1&product="+product+"&initial="+initial+"&parameter="+parameter+"&data_timestamp__gte="+data_timestamp__gte+"&range_start="+range_start;
      if(range_end != null){
        url = url + "&range_end="+range_end;
      }
      if(data_timestamp__lte != null){
        url = url + "&data_timestamp__lte="+data_timestamp__lte;
      }
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },
  async getRegistrationECMWF(jenis,name, data_timestamp__gte = null) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    try {
      let url = Helper.getBaseUrl()+"/@register_integration?service=ecmwf&user_id=1&jenis="+jenis+"&name="+name+"&date="+data_timestamp__gte;
     
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },

  async getRegistrationRadar(lokasi = null,produk = null, parameter = null,dateTimeStart = null, dateTimeEnd = null,hour_start = null, hour_end = null, minute_start = null, minute_end = null) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    let dateStartConvert = dateTimeStart+"T"+hour_start+":"+minute_start;
    let dateEndConvert = dateTimeEnd+"T"+hour_end+":"+minute_end;
    try {
      //@register_integration?name=radar&user_id=1&lokasi=ACE&produk=tif&data_timestamp__gte=2022-03-09T00:00&data_timestamp__lte=2022-03-09T23:59&parameter=PAC24H
      let url = Helper.getBaseUrl()+"/@register_integration?service=radar&user_id=1&lokasi="+lokasi+"&produk="+produk+"&data_timestamp__gte="+dateStartConvert+"&data_timestamp__lte="+dateEndConvert+"&parameter="+parameter;
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },

  async getRegistrationSatelit(tipe = null,produk = null,channel = null,format = null,dateTimeStart = null,dateTimeEnd = null) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    try {
      let url = Helper.getBaseUrl()+"/@register_integration?service=satelit&user_id=1&tipe="+tipe+"&produk="+produk+"&channel="+channel+"&format="+format+"&data_timestamp__gte="+dateTimeStart+"&data_timestamp__lte="+dateTimeEnd;
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },
  async getRegistrationRason(file = null,wmo = null, date_start = null,date_end = null,hour_start = null, hour_end = null, minute_start = null, minute_end = null) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    let dateStartConvert = date_start+'T'+hour_start+':'+minute_start;
    let dateEndConvert = date_end+'T'+hour_end+':'+minute_end;
    file = file.toLowerCase();
    try {
      let url = Helper.getBaseUrl()+"/@register_integration?service="+file+"&user_id=1&wmo_number="+wmo+"&pbs_timestamp__gte="+dateStartConvert+"&obs_timestamp__lte="+dateEndConvert;
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },
  async getRegistrationFileRason(file = null,wmo = null, date_start = null,date_end = null) {
    let userLogin = JSON.parse(localStorage.getItem("userLogin"));
    let userId = userLogin.id;
    let dateStartConvert = date_start;
    let dateEndConvert = date_end;
    file = file.toLowerCase();
    try {
      let url = Helper.getBaseUrl()+"/@register_integration?service=rason&product="+file+"&user_id=1&wmo_number="+wmo+"&data_timestamp__gte="+dateStartConvert+"&data_timestamp__lte="+dateEndConvert+"&time=all";
      let axi = await axios.get(url, Helper.getConfig());
      if(axi.status == 200){
        return axi;
      }else{
        return null;
      }
    } catch (error) {
      return null
    }
  },
  
};
